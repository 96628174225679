@import "../utils/import-mixin-and-var-scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body.disable-scroll {
  height: 100vh;
  overflow: hidden;
}

.app {
  min-width: $min-width;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $color-text;
  @include web-text-default();

  main {
    flex-grow: 1;
  }
}

a {
  color: $color-text-link;
  text-decoration: underline;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    text-decoration: none;
  }
}

button,
label {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: transparent;
  }
}
input,
textarea {
  //-webkit-appearance: none;
  //appearance: none;
  border-radius: 0;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

@media print {
  * {
    background: transparent !important;
    color: #000000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

//text for readers
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// select text on page
//body::-moz-selection {
//  background: rgba(255, 255, 0, 1);
//  color: rgb(0, 0, 0);
//}
//body::-webkit-selection {
//  background: rgba(255, 255, 0, 1);
//  color: rgb(0, 0, 0);
//}
//body::selection {
//  background: rgba(255, 255, 0, 1);
//  color: rgb(0, 0, 0);
//}

// HAPPY BROWSER
.browse-happy {
  background: $color-background;
  color: $color-text;
  padding: 10px;
  text-align: center;
}

.create-banner {
  color: #9636f5;
}
.schedule-banner {
  color: #1cd35a;
}
.switch-banner {
  color: #ff326f;
}

.anchor {
  position: relative;
  top: -96px;
  @include media-tablet-down() {
    top: -64px;
  }
}
