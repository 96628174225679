@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src:
    url("../fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("../fonts/AvenirLTStd-Book.woff") format("woff"),
    url("../fonts/AvenirLTStd-Book.otf") format("otf");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  src:
    url("../fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("../fonts/AvenirLTStd-Roman.woff") format("woff"),
    url("../fonts/AvenirLTStd-Roman.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  src:
    url("../fonts/AvenirLTProHeavy.woff2") format("woff2"),
    url("../fonts/AvenirLTProHeavy.woff") format("woff"),
    url("../fonts/AvenirLTProHeavy.otf") format("otf");
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src:
    url("../fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("../fonts/AvenirLTStd-Black.woff") format("woff"),
    url("../fonts/AvenirLTStd-Black.otf") format("otf");
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src:
    url("../fonts/SourceSansPro-Regular.woff2") format("woff2"),
    url("../fonts/SourceSansPro-Regular.woff") format("woff"),
    url("../fonts/SourceSansPro-Regular.otf") format("otf");
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 500;
  src:
    url("../fonts/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("../fonts/SourceSansPro-SemiBold.woff") format("woff"),
    url("../fonts/SourceSansPro-SemiBold.otf") format("otf");
  font-display: swap;
}
