@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

menu, ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/AvenirLTStd-Book.woff2") format("woff2"), url("../fonts/AvenirLTStd-Book.woff") format("woff"), url("../fonts/AvenirLTStd-Book.otf") format("otf");
  font-display: swap; }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/AvenirLTStd-Roman.woff2") format("woff2"), url("../fonts/AvenirLTStd-Roman.woff") format("woff"), url("../fonts/AvenirLTStd-Roman.ttf") format("truetype");
  font-display: swap; }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/AvenirLTProHeavy.woff2") format("woff2"), url("../fonts/AvenirLTProHeavy.woff") format("woff"), url("../fonts/AvenirLTProHeavy.otf") format("otf");
  font-display: swap; }

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/AvenirLTStd-Black.woff2") format("woff2"), url("../fonts/AvenirLTStd-Black.woff") format("woff"), url("../fonts/AvenirLTStd-Black.otf") format("otf");
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/SourceSansPro-Regular.woff2") format("woff2"), url("../fonts/SourceSansPro-Regular.woff") format("woff"), url("../fonts/SourceSansPro-Regular.otf") format("otf");
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SourceSansPro-SemiBold.woff2") format("woff2"), url("../fonts/SourceSansPro-SemiBold.woff") format("woff"), url("../fonts/SourceSansPro-SemiBold.otf") format("otf");
  font-display: swap; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body.disable-scroll {
  height: 100vh;
  overflow: hidden; }

.app {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #000000;
  font-family: "Avenir", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal; }
  .app main {
    flex-grow: 1; }

a {
  color: #0000ff;
  text-decoration: underline;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }
  a:hover {
    text-decoration: none; }

button,
label {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  padding: 0; }
  button:hover, button:active, button:visited, button:focus,
  label:hover,
  label:active,
  label:visited,
  label:focus {
    background-color: transparent; }

input,
textarea {
  border-radius: 0;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0; }

@media print {
  * {
    background: transparent !important;
    color: #000000 !important;
    box-shadow: none !important;
    text-shadow: none !important; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.browse-happy {
  background: #eeeeee;
  color: #000000;
  padding: 10px;
  text-align: center; }

.create-banner {
  color: #9636f5; }

.schedule-banner {
  color: #1cd35a; }

.switch-banner {
  color: #ff326f; }

.anchor {
  position: relative;
  top: -96px; }
  @media only screen and (max-width: 767px) {
    .anchor {
      top: -64px; } }

.section {
  padding: 48px 0; }
  @media only screen and (max-width: 1300px) {
    .section {
      padding: 32px 0; } }
  @media only screen and (max-width: 767px) {
    .section {
      padding: 24px 0; } }

.wr-section {
  max-width: 1328px;
  margin: 0 auto;
  padding: 0 16px; }

.effect-card {
  border: 2px solid #eeeeee;
  border-radius: 8px; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1764px; }
  .header__menu {
    display: flex; }
  .header__menu-item {
    color: black;
    font-weight: 800;
    line-height: 24px;
    font-size: 20px;
    margin: 0 24px;
    transition: opacity 0.2s linear; }
    .header__menu-item:hover {
      opacity: 0.4; }
    @media only screen and (max-width: 1300px) {
      .header__menu-item {
        margin: 0 15px; } }
    @media only screen and (max-width: 767px) {
      .header__menu-item {
        display: none; } }
  .header__icon {
    width: 130px;
    height: 28px; }
    @media only screen and (max-width: 460px) {
      .header__icon {
        width: 112px;
        height: 24px; } }
  .header__download {
    width: 144px;
    height: 48px; }
    @media only screen and (max-width: 767px) {
      .header__download {
        width: 114px;
        height: 38px; } }

.header-block {
  background-color: white;
  width: 100%;
  z-index: 100;
  position: fixed;
  border-bottom: 1px solid #f2f1f6;
  padding: 24px 48px;
  max-height: 96px; }
  @media only screen and (max-width: 1300px) {
    .header-block {
      padding: 12px 16px; } }
  @media only screen and (max-width: 767px) {
    .header-block {
      max-height: 64px; } }

.scrollHeader {
  right: 0;
  opacity: 1; }

a {
  text-decoration: none; }

.mainBanner {
  position: relative;
  display: flex;
  border-radius: 32px;
  padding: 105px 110px;
  margin-top: 202px; }
  @media only screen and (max-width: 1300px) {
    .mainBanner {
      position: static;
      text-align: center;
      padding: 48px 24px;
      margin-top: 112px;
      border-radius: 20px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .mainBanner {
      margin-top: 80px; } }
  .mainBanner__plan-bg {
    background: linear-gradient(77.76deg, rgba(245, 112, 16, 0.1) 0%, rgba(255, 42, 119, 0.1) 100%); }
  .mainBanner__info-block {
    max-width: 530px; }
    @media only screen and (max-width: 1300px) {
      .mainBanner__info-block {
        max-width: none; } }
  .mainBanner__title {
    font-family: "Avenir-black", sans-serif;
    font-weight: 900;
    line-height: 64px;
    font-size: 56px; }
    @media only screen and (max-width: 1300px) {
      .mainBanner__title {
        margin-top: 20px; } }
    @media only screen and (max-width: 767px) {
      .mainBanner__title {
        line-height: 36px;
        font-size: 32px; } }
  .mainBanner__subtitle {
    color: rgba(0, 0, 0, 0.4);
    margin-top: 16px;
    font-weight: 500;
    line-height: 28px;
    font-size: 17px; }
    @media only screen and (max-width: 767px) {
      .mainBanner__subtitle {
        margin-top: 16px;
        line-height: 24px;
        font-size: 15px; } }
  .mainBanner__download {
    margin-top: 32px;
    width: 192px;
    height: 64px; }
  .mainBanner__img-block {
    position: absolute;
    bottom: -5px;
    right: 0; }
    @media only screen and (max-width: 1300px) {
      .mainBanner__img-block {
        position: static; }
        .mainBanner__img-block::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          position: relative;
          bottom: 6px;
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, #000000 53.76%, rgba(0, 0, 0, 0.1) 100%);
          opacity: 0.8; } }
  .mainBanner__img {
    width: 100%;
    max-height: 608px;
    max-width: 636px; }

.banner {
  position: relative;
  display: flex;
  border-radius: 32px;
  padding: 105px 110px;
  margin-top: 64px;
  min-height: 512px;
  margin-right: 0;
  margin-left: 188px; }
  .banner__create-bg {
    background: linear-gradient(77.76deg, rgba(240, 101, 209, 0.1) 0%, rgba(63, 38, 134, 0.1) 100%); }
  .banner__schedule-bg {
    background: linear-gradient(259.41deg, rgba(0, 240, 255, 0.1) 0%, rgba(51, 255, 0, 0.1) 100%); }
  .banner__switch-bg {
    background: linear-gradient(77.76deg, rgba(245, 112, 16, 0.1) 0%, rgba(255, 42, 119, 0.1) 100%); }
  @media only screen and (max-width: 1300px) {
    .banner {
      text-align: center;
      padding: 48px 24px;
      margin: 16px 0 0 0;
      border-radius: 20px;
      align-items: center;
      justify-content: space-between; } }
  .banner--reverse {
    flex-direction: row-reverse;
    position: relative;
    margin-left: 0;
    margin-right: 188px; }
    @media only screen and (max-width: 1300px) {
      .banner--reverse {
        margin: 16px 0 0 0; } }
  @media only screen and (max-width: 1300px) {
    .banner--column {
      flex-direction: column;
      padding: 48px 24px 0; } }
  @media only screen and (max-width: 1300px) {
    .banner--column-reverse {
      flex-direction: column-reverse;
      padding: 0 24px 48px; } }
  .banner__info-block {
    max-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media only screen and (max-width: 1300px) {
      .banner__info-block {
        max-width: none; } }
  .banner__title {
    font-family: Avenir-black, sans-serif;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px; }
    @media only screen and (max-width: 767px) {
      .banner__title {
        font-size: 24px;
        line-height: 32px; } }
  .banner__subtitle {
    color: rgba(0, 0, 0, 0.4);
    margin-top: 16px;
    font-weight: 500;
    line-height: 32px;
    font-size: 20px; }
    @media only screen and (max-width: 767px) {
      .banner__subtitle {
        margin-top: 16px;
        line-height: 24px;
        font-size: 15px; } }
  .banner__download {
    margin-top: 32px;
    width: 192px;
    height: 64px; }
  .banner__img-block {
    position: absolute;
    bottom: 0;
    right: 100px; }
    @media only screen and (max-width: 1400px) {
      .banner__img-block {
        right: 0; } }
    .banner__img-block--reverse {
      right: auto;
      left: 100px; }
      @media only screen and (max-width: 1400px) {
        .banner__img-block--reverse {
          left: 0; } }
    @media only screen and (max-width: 1300px) {
      .banner__img-block {
        position: static; } }
  .banner__img {
    display: block;
    max-height: 513px;
    max-width: 636px; }
    @media only screen and (max-width: 767px) {
      .banner__img {
        width: 100%; } }

.banner-section {
  max-width: 1516px; }

.achievements {
  margin: 128px 0;
  margin-left: 110px; }
  @media only screen and (max-width: 1300px) {
    .achievements {
      margin: 128px 55px; } }
  @media only screen and (max-width: 767px) {
    .achievements {
      margin: 64px 0 32px;
      margin-left: 0;
      text-align: center;
      padding: 0 40px; } }
  .achievements__list {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .achievements__list {
        flex-direction: column;
        align-items: center; } }
  .achievements__achievement {
    margin-right: 7.4%; }
    @media only screen and (max-width: 767px) {
      .achievements__achievement {
        margin: 0;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .achievements__icon-block {
    width: 86px;
    height: 86px;
    box-shadow: 0 0 16px rgba(221, 99, 102, 0.08), 0 16px 16px rgba(221, 99, 102, 0.16);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .achievements__head-title {
    max-width: 600px;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .achievements__head-title {
        max-width: none;
        font-size: 24px;
        line-height: 33px; } }
  .achievements__title {
    margin-bottom: 16px;
    margin-top: 24px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px; }
    @media only screen and (max-width: 767px) {
      .achievements__title {
        font-size: 20px;
        line-height: 27px; } }
  .achievements__subtitle {
    font-weight: 500;
    opacity: 0.4;
    line-height: 24px; }

.reviews {
  margin-top: 128px; }
  @media only screen and (max-width: 767px) {
    .reviews {
      margin-top: 64px; } }
  .reviews__head-title {
    text-align: center;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .reviews__head-title {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 32px; } }
  .reviews__list {
    font-family: "SourceSansPro", sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .reviews__name {
    position: absolute;
    right: 15px;
    top: 17px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    opacity: 0.4;
    font-weight: 400; }
  .reviews__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em; }
  .reviews__review {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em; }
  .reviews__item {
    position: relative;
    flex: 0 1 32%;
    padding: 16px 16px 20px;
    background: #f2f1f6;
    border-radius: 16px;
    margin-bottom: 30px; }
    @media only screen and (max-width: 1300px) {
      .reviews__item {
        flex: 0 1 49%;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .reviews__item {
        flex: 0 1 100%;
        margin-bottom: 12px; } }
  .reviews__icon {
    width: 12px;
    height: 12px; }
  .reviews__score {
    display: flex;
    margin: 6px 0 12px; }

.mail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 162px;
  margin-bottom: 192px; }
  .mail__icon-block {
    width: 116px;
    height: 116px;
    box-shadow: 0 0 16px rgba(221, 99, 102, 0.08), 0 16px 16px rgba(221, 99, 102, 0.16);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .mail__title {
    text-align: center;
    margin: 48px 0 16px;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px; }
    @media only screen and (max-width: 767px) {
      .mail__title {
        font-size: 32px;
        line-height: 44px; } }
  .mail__link {
    position: relative;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    background: linear-gradient(to right, #862666, #f07065);
    background-clip: text;
    -webkit-text-fill-color: transparent; }
    @media only screen and (max-width: 767px) {
      .mail__link {
        font-size: 24px;
        line-height: 33px; } }
    .mail__link::after {
      content: "";
      background: linear-gradient(to right, #862666, #f07065);
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 5px; }

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 36px 48px;
  border-top: 1px solid #f2f1f6;
  max-height: 96px; }
  @media only screen and (max-width: 1300px) {
    .footer {
      padding: 16px 0 24px;
      max-height: none; } }
  @media only screen and (max-width: 767px) {
    .footer {
      justify-content: center;
      flex-direction: column; } }
  .footer__container {
    display: flex;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .footer__container {
        text-align: center;
        justify-content: center; } }
    @media only screen and (max-width: 538px) {
      .footer__container > ul > li:first-child > a::before {
        content: none; } }
  .footer__list {
    display: flex;
    align-items: center; }
  .footer__item {
    position: relative;
    color: black;
    margin: 0 14px;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.4; }
    .footer__item::before {
      position: absolute;
      left: -15px;
      top: -2px;
      content: "·";
      display: inline-block; }
    .footer__item:not(.footer__rights):hover {
      text-decoration: underline; }
  @media only screen and (max-width: 767px) {
    .footer__list-item {
      margin-bottom: 14px; } }
  .footer__icons {
    display: flex; }
  .footer__icon {
    display: block; }
  .footer__link {
    margin: 0 18px;
    display: block; }
