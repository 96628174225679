@import "../../utils/import-mixin-and-var-scss";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 36px 48px;
  border-top: 1px solid rgb(242, 241, 246);
  max-height: 96px;
  @include media-laptop-down() {
    padding: 16px 0 24px;
    max-height: none;
  }
  @include media-tablet-down() {
    justify-content: center;
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    @include media-tablet-down() {
      text-align: center;
      justify-content: center;
    }

    @media only screen and (max-width: 538px) {
      > ul > li:first-child > a::before {
        content: none;
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
  }
  &__item {
    position: relative;
    color: black;
    margin: 0 14px;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.4;

    &::before {
      position: absolute;
      left: -15px;
      top: -2px;
      content: "·";
      display: inline-block;
    }

    &:not(.footer__rights):hover {
      text-decoration: underline;
    }
  }
  &__list-item {
    @include media-tablet-down() {
      margin-bottom: 14px;
    }
  }
  &__icons {
    display: flex;
  }
  &__icon {
    display: block;
  }
  &__link {
    margin: 0 18px;
    display: block;
  }
}
