@import "../../utils/import-mixin-and-var-scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1764px;

  //&__link {
  //  transition: right 0.4s ease-out;
  //  @include media-mobile-down() {
  //    position: relative;
  //    right: calc(-50% + 48px);
  //  }
  //}

  &__menu {
    display: flex;
  }
  &__menu-item {
    &:hover {
      opacity: 0.4;
    }

    color: black;
    font-weight: 800;
    line-height: 24px;
    font-size: 20px;
    margin: 0 24px;
    transition: opacity 0.2s linear;
    @include media-laptop-down() {
      margin: 0 15px;
    }
    @include media-tablet-down() {
      display: none;
    }
  }

  &__icon {
    width: 130px;
    height: 28px;
    @include media-mobile-down() {
      width: 112px;
      height: 24px;
    }
  }
  &__download {
    width: 144px;
    height: 48px;
    @include media-tablet-down() {
      width: 114px;
      height: 38px;
    }
    //@include media-mobile-down() {
    //  opacity: 0;
    //  transition: opacity 0.3s linear;
    //}
  }
}

.header-block {
  background-color: white;
  width: 100%;
  z-index: 100;
  position: fixed;
  border-bottom: 1px solid rgb(242, 241, 246);
  padding: 24px 48px;
  max-height: 96px;
  @include media-laptop-down() {
    padding: 12px 16px;
  }
  @include media-tablet-down() {
    max-height: 64px;
  }
}
.scrollHeader {
  right: 0;
  opacity: 1;
}
a {
  text-decoration: none;
}
