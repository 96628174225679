.banner {
  position: relative;
  display: flex;
  border-radius: 32px;
  padding: 105px 110px;
  margin-top: 64px;
  min-height: 512px;
  margin-right: 0;
  margin-left: 188px;

  &__create-bg {
    background: linear-gradient(77.76deg, rgba(240, 101, 209, 0.1) 0%, rgba(63, 38, 134, 0.1) 100%);
  }
  &__schedule-bg {
    background: linear-gradient(259.41deg, rgba(0, 240, 255, 0.1) 0%, rgba(51, 255, 0, 0.1) 100%);
  }
  &__switch-bg {
    background: linear-gradient(77.76deg, rgba(245, 112, 16, 0.1) 0%, rgba(255, 42, 119, 0.1) 100%);
  }

  @include media-laptop-down() {
    text-align: center;
    padding: 48px 24px;
    margin: 16px 0 0 0;
    border-radius: 20px;
    align-items: center;
    justify-content: space-between;
  }

  &--reverse {
    flex-direction: row-reverse;
    position: relative;
    margin-left: 0;
    margin-right: 188px;
    @include media-laptop-down() {
      margin: 16px 0 0 0;
    }
  }

  &--column {
    @include media-laptop-down() {
      flex-direction: column;
      padding: 48px 24px 0;
    }
  }
  &--column-reverse {
    @include media-laptop-down() {
      flex-direction: column-reverse;
      padding: 0 24px 48px;
    }
  }

  &__info-block {
    max-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-laptop-down() {
      max-width: none;
    }
  }

  &__title {
    font-family: Avenir-black, sans-serif;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    @include media-tablet-down() {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__subtitle {
    color: rgba(0, 0, 0, 0.4);
    margin-top: 16px;
    font-weight: 500;
    line-height: 32px;
    font-size: 20px;
    @include media-tablet-down() {
      margin-top: 16px;
      line-height: 24px;
      font-size: 15px;
    }
  }

  &__download {
    margin-top: 32px;
    width: 192px;
    height: 64px;
  }

  &__img-block {
    position: absolute;
    bottom: 0;
    right: 100px;
    @media only screen and (max-width: 1400px) {
      right: 0;
    }
    &--reverse {
      right: auto;
      left: 100px;
      @media only screen and (max-width: 1400px) {
        left: 0;
      }
    }
    @include media-laptop-down() {
      position: static;
    }
  }
  &__img {
    display: block;
    max-height: 513px;
    max-width: 636px;
    @include media-tablet-down() {
      width: 100%;
    }
  }
}

.banner-section {
  max-width: 1516px;
}
