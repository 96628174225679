.reviews {
  margin-top: 128px;
  @include media-tablet-down() {
    margin-top: 64px;
  }

  &__head-title {
    text-align: center;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 64px;
    @include media-tablet-down() {
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 32px;
    }
  }


  &__list {
    font-family: "SourceSansPro", sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__name {
    position: absolute;
    right: 15px;
    top: 17px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    opacity: 0.4;
    font-weight: 400;
  }
  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
  }
  &__review {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }
  &__item {
    position: relative;
    flex: 0 1 32%;
    padding: 16px 16px 20px;
    background: #f2f1f6;
    border-radius: 16px;
    margin-bottom: 30px;
    @include media-laptop-down() {
      flex: 0 1 49%;
      margin-bottom: 20px;
    }
    @include media-tablet-down() {
      flex: 0 1 100%;
      margin-bottom: 12px;
    }
  }
  &__icon {
    width: 12px;
    height: 12px;
  }
  &__score {
    display: flex;
    margin: 6px 0 12px;
  }
}
