.mainBanner {
  position: relative;
  display: flex;
  border-radius: 32px;
  padding: 105px 110px;
  margin-top: 202px;

  @include media-laptop-down() {
    position: static;
    text-align: center;
    padding: 48px 24px;
    margin-top: 112px;
    border-radius: 20px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
  @include media-tablet-down() {
    margin-top: 80px;
  }
  &__plan-bg {
    background: linear-gradient(77.76deg, rgba(245, 112, 16, 0.1) 0%, rgba(255, 42, 119, 0.1) 100%);
  }
  &__info-block {
    max-width: 530px;
    @include media-laptop-down() {
      max-width: none;
    }
  }

  &__title {
    font-family: "Avenir-black", sans-serif;
    font-weight: 900;
    line-height: 64px;
    font-size: 56px;
    @include media-laptop-down() {
      margin-top: 20px;
    }
    @include media-tablet-down() {
      line-height: 36px;
      font-size: 32px;
    }
  }
  &__subtitle {
    color: rgba(0, 0, 0, 0.4);
    margin-top: 16px;
    font-weight: 500;
    line-height: 28px;
    font-size: 17px;
    @include media-tablet-down() {
      margin-top: 16px;
      line-height: 24px;
      font-size: 15px;
    }
  }

  &__download {
    margin-top: 32px;
    width: 192px;
    height: 64px;
  }

  &__img-block {
    position: absolute;
    bottom: -5px;
    right: 0;
    @include media-laptop-down() {
      position: static;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: relative;
        bottom: 6px;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, #000000 53.76%, rgba(0, 0, 0, 0.1) 100%);
        opacity: 0.8;
      }
    }
  }
  &__img {
    width: 100%;
    max-height: 608px;
    max-width: 636px;
  }
}
