@import "../../utils/import-mixin-and-var-scss";

.achievements {
  margin: 128px 0;
  margin-left: 110px;

  @include media-laptop-down() {
    margin: 128px 55px;
  }

  @include media-tablet-down() {
    margin: 64px 0 32px;
    margin-left: 0;
    text-align: center;
    padding: 0 40px;
  }

  &__list {
    display: flex;

    @include media-tablet-down() {
      flex-direction: column;
      align-items: center;
    }
  }
  &__achievement {
    margin-right: 7.4%;
    @include media-tablet-down() {
      margin: 0;
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__icon-block {
    width: 86px;
    height: 86px;
    box-shadow: 0 0 16px rgba(221, 99, 102, 0.08), 0 16px 16px rgba(221, 99, 102, 0.16);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__head-title {
    max-width: 600px;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 64px;
    @include media-tablet-down() {
      max-width: none;
      font-size: 24px;
      line-height: 33px;
    }
  }
  &__title {
    margin-bottom: 16px;
    margin-top: 24px;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    @include media-tablet-down() {
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__subtitle {
    font-weight: 500;
    opacity: 0.4;
    line-height: 24px;
  }
}
