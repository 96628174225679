@import "../mixins/media";

@mixin web-text-default {
  font-family: $font-text;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
