.mail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 162px;
  margin-bottom: 192px;

  &__icon-block {
    width: 116px;
    height: 116px;
    box-shadow: 0 0 16px rgba(221, 99, 102, 0.08), 0 16px 16px rgba(221, 99, 102, 0.16);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__title {
    text-align: center;
    margin: 48px 0 16px;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    @include media-tablet-down() {
      font-size: 32px;
      line-height: 44px;
    }
  }
  &__link {
    position: relative;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    background: linear-gradient(to right, rgba(134, 38, 102, 1), rgba(240, 112, 101, 1));
    background-clip: text;
    -webkit-text-fill-color: transparent;
    @include media-tablet-down() {
      font-size: 24px;
      line-height: 33px;
    }
    &::after {
      content: "";
      background: linear-gradient(to right, rgba(134, 38, 102, 1), rgba(240, 112, 101, 1));
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 5px;
    }
  }
}
